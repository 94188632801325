body {
  background-image: linear-gradient(134deg, #abdbed 10%, #4093dcd2 100%);
  color: white;
  text-align: center;
  min-height: 100vh;
}




